import { combineReducers, configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { combineEpics, createEpicMiddleware, Epic, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface TempAction extends Action {
  type: "TEMP",
  code: string,
}

type AllActions = TempAction;

export interface StoreState {
}

const rootReducer = combineReducers({
});

const epicMiddleware = createEpicMiddleware();

type EpicType = Epic<AllActions, any, StoreState, any>;
function protect(epic: EpicType) {
  return (action$: Observable<AllActions>, state$: StateObservable<StoreState>, deps: any)  =>
    epic(action$, state$, deps).pipe(
      catchError(error => of({ type: 'EPIC_FAILURE', error })),
    );
}

const epics: EpicType[] = [];

const protectedEpics = Array.from(epics).map(epic => protect(epic));

const rootEpic: Epic<any, any, any, any> = combineEpics(...protectedEpics);

const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(epicMiddleware),
});
epicMiddleware.run(rootEpic);

export default store;

export type StoreDispatch = typeof store.dispatch
