import * as React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import { StoreDispatch, StoreState } from 'cls/store';
import Urls from 'cls/Urls';
import { Logo } from 'ui/logo';

import 'page/main/style.css';

interface Props {
}
interface ReduxStateProps {
}
interface ReduxDispatchProps {
}
interface State {
}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  render() {

    const logo = <LinkContainer to="/">
      <Navbar.Brand href="/" className="d-flex align-items-center mr-4">
        <Logo className="mr-2" />
        <span>{process.env.REACT_APP_NAME}</span>
      </Navbar.Brand>
    </LinkContainer>;

    return (
      <header className="bg-light box-shadow-sm fixed-top d-block">
        <Navbar bg="light" expand="lg" sticky="top" className="justify-content-between">
          <Container fluid={true}>
            { logo }
            <Nav>
              <Nav.Link href={Urls.REAL_PAGE} target="_blank">
                {process.env.REACT_APP_REAL_NAME} <i className="czi-sign-in" />
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = (state: StoreState): ReduxStateProps => {
  return ({
  });
};

const mapDispatchToProps = (dispatch: StoreDispatch) => {
  return {
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const Header = () => (
  <ConnectedScene />
);

export default Header;
