import {  Observable, throwError} from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, switchMap } from 'rxjs/operators';

import { Def } from 'cls/Content';

export default class Api {
  static BASE = process.env.REACT_APP_API_URL;
  private static URLS = {
    HELP: (id: string) => `${Api.BASE}/${id}.json`,
    HOMEPAGE: `${Api.BASE}/homepage.json`,
  };
  /**
   * Do http request and return Observable.
   *
   * To do http request we use fetch API.
   * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
   *
   * Then result is changed into RxJS Observables which we use to handle async.
   *
   * @param url - full request url
   * @param method - GET/POST/...
   * @param data - data to be send to backend in request body
   */
  private static doRequest<T>(
    url: string, method: string, data: any = undefined,
  ): Observable<T> {
    const body = data !== undefined ? JSON.stringify(data) : undefined;
    return fromFetch(url, { method, body }).pipe(
      switchMap((x) => x.json()),
      catchError((err) => {
        console.error(`Http error on ${method} ${url}`, err); // eslint-disable-line no-console
        return throwError(err);
      }),
    );
  }

  public static getHelpPage(id?: string): Observable<Def> {
    if (id === undefined || id === "") {
      return Api.doRequest(this.URLS.HOMEPAGE, 'GET', undefined);
    }
    return Api.doRequest(this.URLS.HELP(id), 'GET', undefined);
  }

}
