import * as _ from 'underscore';

import { eatTokens, Token, TokenLink, TokenNum, TokenText } from 'cls/Token';

export interface ParsedRawText {type: 'ParsedRawText', text: string} // raw text
export interface ParsedB {type: 'ParsedB', text: ParsedTextItem[]} // **text**
export interface ParsedBr {type: 'ParsedBr'} // \n
export interface ParsedI {type: 'ParsedI', text: ParsedTextItem[]} // _text_
export interface ParsedIcon {type: 'ParsedIcon', text: string} // __czi-rocket__
export interface ParsedButton {type: 'ParsedButton', variant: string, text: ParsedTextItem[]} // ___czi-rocket___
export interface ParsedNums {type: 'ParsedNums', text: ParsedTextItem[][], orders: string[]} // 1. text \n2. text\n

export type ParsedTextItem =
  ParsedRawText |
  ParsedUrl |
  ParsedB |
  ParsedBr |
  ParsedIcon |
  ParsedButton |
  ParsedI;


export interface ParsedUrl {text: string, link: string, type: 'ParsedUrl'} // [text](link)
export type Parsed =
  ParsedTextItem |
  ParsedNums;


const checkTypes = (tx: string[], tokens: Token[]): boolean => {
  if (tokens.length < tx.length) { return false; }
  return _.all(tx.map((x, xi) => tokens[xi].type === x));
}

export const eatParsed = (tokens: Token[], until?: string): [Parsed, Token[]] | undefined => {
  if (until !== undefined && checkTypes([until], tokens)) {
    return undefined;
  }

  if (checkTypes(["TokenButton"], tokens)) {
    const bParsed = eatParseds(tokens.slice(1), "TokenButton");
    if (bParsed === undefined) {
      console.log('Can\'t parse button: ', tokens.slice(1));
      return undefined;
    }
    if (!checkTypes(["TokenButton"], bParsed[1])) {
      console.log('Can\'t parse num, no button: ', tokens, bParsed);
      return undefined;
    }
    const parsedTextItem: ParsedTextItem[] = [];
    bParsed[0].forEach((p) => {
      switch(p.type) {
        case 'ParsedB': parsedTextItem.push(p); break;
        case 'ParsedI': parsedTextItem.push(p); break;
        case 'ParsedBr': parsedTextItem.push(p); break;
        case 'ParsedUrl': parsedTextItem.push(p); break;
        case 'ParsedIcon': parsedTextItem.push(p); break;
        case 'ParsedButton': parsedTextItem.push(p); break;
        case 'ParsedRawText': parsedTextItem.push(p); break;
        default:
          console.log('Wrong type for num content', p);
      }
    });
    let variant = 'primary';
    const allowedVariants = [
      'primary', 'secondary', 'danger', 'info',
      'outline-primary', 'outline-secondary', 'outline-danger', 'outline-info',
    ];
    if (
      parsedTextItem.length > 0 &&
      parsedTextItem[0].type === 'ParsedRawText'
    ) {
      const i = parsedTextItem[0] as ParsedRawText;
      allowedVariants.forEach((allowedVariant) => {
        if (i.text.startsWith(allowedVariant + ',')) {
          variant = allowedVariant;
          i.text = i.text.replace(allowedVariant+',', '');
        }
      });
    }
    return [
      {
        type: 'ParsedButton',
        text: parsedTextItem,
        variant,
      },
      bParsed[1].splice(1),
    ];
  }

  if (checkTypes(["TokenIcon", "TokenText", "TokenIcon"], tokens)) {
    return [
      {
        type: 'ParsedIcon',
        text: (tokens[1] as TokenText).text,
      },
      tokens.slice(3),
    ];
  }
  if (checkTypes(["TokenUrlNameS", "TokenText", "TokenUrlNameE", "TokenLink"], tokens)) {
    return [
      {
        type: 'ParsedUrl',
        text: (tokens[1] as TokenText).text,
        link: (tokens[3] as TokenLink).text.slice(1, -1)
      },
      tokens.slice(4),
    ];
  }
  if (checkTypes(["TokenText"], tokens)) {
    return [
      {
        type: 'ParsedRawText',
        text: (tokens[0] as TokenText).text,
      },
      tokens.slice(1),
    ];
  }

  if (checkTypes(["TokenB"], tokens)) {
    const bParsed = eatParseds(tokens.slice(1), "TokenB");
    if (bParsed === undefined) {
      console.log('Can\'t parse b: ', tokens.slice(1));
      return undefined;
    }
    if (!checkTypes(["TokenB"], bParsed[1])) {
      console.log('Can\'t parse num, no b: ', tokens, bParsed);
      return undefined;
    }
    const parsedTextItem: ParsedTextItem[] = [];
    bParsed[0].forEach((p) => {
      switch(p.type) {
        case 'ParsedB': parsedTextItem.push(p); break;
        case 'ParsedI': parsedTextItem.push(p); break;
        case 'ParsedBr': parsedTextItem.push(p); break;
        case 'ParsedUrl': parsedTextItem.push(p); break;
        case 'ParsedIcon': parsedTextItem.push(p); break;
        case 'ParsedButton': parsedTextItem.push(p); break;
        case 'ParsedRawText': parsedTextItem.push(p); break;
        default:
          console.log('Wrong type for num content', p);
      }
    });
    return [
      {
        type: 'ParsedB',
        text: parsedTextItem,
      },
      bParsed[1].splice(1),
    ];
  }

  if (checkTypes(["TokenNum"], tokens)) {
    const n: ParsedNums = {
      type: 'ParsedNums',
      text: [],
      orders: [],
    };
    let resultTokens = [...tokens];
    while (checkTypes(["TokenNum"], resultTokens)) {
      const tokenNum = resultTokens[0];
      const numParsed = eatParseds(resultTokens.splice(1), "TokenBreak");
      if (numParsed === undefined) {
        console.log('Can\'t parse num: ', resultTokens);
        return undefined;
      }
      resultTokens = numParsed[1];
      if (!checkTypes(["TokenBreak"], resultTokens)) {
        console.log('Can\'t parse num, no break: ', numParsed);
        return undefined;
      }
      resultTokens = resultTokens.splice(1);

      const parsedTextItem: ParsedTextItem[] = [];
      numParsed[0].forEach((p) => {
        switch(p.type) {
          case 'ParsedB': parsedTextItem.push(p); break;
          case 'ParsedI': parsedTextItem.push(p); break;
          case 'ParsedBr': parsedTextItem.push(p); break;
          case 'ParsedUrl': parsedTextItem.push(p); break;
          case 'ParsedIcon': parsedTextItem.push(p); break;
          case 'ParsedButton': parsedTextItem.push(p); break;
          case 'ParsedRawText': parsedTextItem.push(p); break;
          default:
            console.log('Wrong type for num content', p);
        }
      })
      n.text.push(parsedTextItem);
      n.orders.push((tokenNum as TokenNum).num);
    }
    return [n, resultTokens];
  }

  if (checkTypes(["TokenBreak"], tokens)) {
    return [
      {
        type: 'ParsedBr',
      },
      tokens.slice(1),
    ];
  }

  return undefined;
}

export const eatParseds = (tokens: Token[], until?: string): [Parsed[], Token[]] => {
  const resultParsed: Parsed[] = [];
  let resultTokens = [...tokens];
  let parsed: Parsed;
  while (true) {
    const oneMore = eatParsed(resultTokens, until);
    if (oneMore === undefined) {
      return [resultParsed, resultTokens];
    }
    if (resultTokens.length === oneMore[1].length) {
      return [resultParsed, resultTokens];
    }
    if (oneMore[0].type === until) {
      return [resultParsed, resultTokens];
    }
    [parsed, resultTokens] = oneMore;
    resultParsed.push(parsed);
  }
}

export const parse = (s: string): Parsed[] => {
  const [tokens, left] = eatTokens(s);
  if (left !== '') {
    console.log('Can\'t eat tokens, left: ', left);
    return [];
  }
  // console.log(tokens);
  const [parsed, leftTokens] = eatParseds(tokens);
  if (leftTokens.length > 0) {
    console.log('Can\'t eat parsed, left: ', leftTokens);
  }
  // console.log(parsed, leftTokens.length);
  return parsed;
}