import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

export const NextStep = () => {
  return (
    <section className="container text-center pt-5 mt-2">
      <h2 className="h4 pb-3">{process.env.REACT_APP_NO_ANSWER}</h2>
      <i className="czi-help h3 text-primary d-block mb-4"/>
      <LinkContainer to="/answer/20">
        <a className="btn btn-primary" href="/answer/20">{process.env.REACT_APP_NO_ANSWER_BUTTON}</a>
      </LinkContainer>
      <p className="font-size-sm pt-4">{process.env.REACT_APP_NO_ANSWER_EXTRA}</p>
    </section>
  );
}