
export interface TokenText {type: 'TokenText', text: string}
export interface TokenLink {type: 'TokenLink', text: string}
export interface TokenB {type: 'TokenB'}
export interface TokenI {type: 'TokenI'}
export interface TokenIcon {type: 'TokenIcon'}
export interface TokenButton {type: 'TokenButton'}
export interface TokenBreak {type: 'TokenBreak'}
export interface TokenUrlNameS {type: 'TokenUrlNameS'}
export interface TokenUrlNameE {type: 'TokenUrlNameE'}
export interface TokenNum {type: 'TokenNum', num: string}
export type Token =
  TokenText |
  TokenB |
  TokenI |
  TokenIcon |
  TokenButton |
  TokenBreak |
  TokenUrlNameS |
  TokenUrlNameE |
  TokenLink |
  TokenNum;

export const b: TokenB = {type: 'TokenB'};
export const i: TokenI = {type: 'TokenI'};
export const icon: TokenIcon = {type: 'TokenIcon'};
export const button: TokenButton = {type: 'TokenButton'};
export const br: TokenBreak = {type: 'TokenBreak'};
export const urlNameS: TokenUrlNameS = {type: 'TokenUrlNameS'};
export const urlNameE: TokenUrlNameE = {type: 'TokenUrlNameE'};


const regB = '\\*\\*';
const regI = '_';
const regIcon = '__';
const regButton = '___';
const regUrlNameS = '\\[';
const regUrlNameE = '\\]';
const letters = 'a-zA-ZĄąĆćĘęŁłŃńÓóŚśŹźŻż';
const regLink = '\\([' + letters + '0-9#-/:\\.+?%]+\\)';
const regText = '[' + letters + '0-9 \'"/|.,-:+?%]+';
const regNum = '[0-9]+\\. ';
const regL1 = ' *\\* ';
const regBreak = '\\n';

export const eatToken = (s: string): [Token, string] | undefined => {
  if (s === '' || s === undefined) { return undefined; }
  if (new RegExp('^' + regB).test(s)) { return [b, s.slice(2)]; }
  if (new RegExp('^' + regButton).test(s)) { return [button, s.slice(3)]; }
  if (new RegExp('^' + regIcon).test(s)) { return [icon, s.slice(2)]; }
  if (new RegExp('^' + regI).test(s)) { return [i, s.slice(1)]; }
  if (new RegExp('^' + regBreak).test(s)) { return [br, s.slice(1)]; }
  if (new RegExp('^' + regUrlNameS).test(s)) { return [urlNameS, s.slice(1)]; }
  if (new RegExp('^' + regUrlNameE).test(s)) { return [urlNameE, s.slice(1)]; }
  if (new RegExp('^' + regL1).test(s)) {
    const sLen = s.length;
    const nextS = s.replace(RegExp('^' + regL1), '');
    const nextSLen = nextS.length;
    const match = s.slice(0, sLen - nextSLen);
    return [{type: 'TokenNum', num: match}, nextS];
  }
  if (new RegExp('^' + regNum).test(s)) {
    const sLen = s.length;
    const nextS = s.replace(RegExp('^' + regNum), '');
    const nextSLen = nextS.length;
    const match = s.slice(0, sLen - nextSLen);
    return [{type: 'TokenNum', num: match}, nextS];
  }
  if (new RegExp('^' + regLink).test(s)) {
    const sLen = s.length;
    const nextS = s.replace(RegExp('^' + regLink), '');
    const nextSLen = nextS.length;
    const match = s.slice(0, sLen - nextSLen);
    return [{type: 'TokenLink', text: match}, nextS];
  }
  if (new RegExp('^' + regText).test(s)) {
    const sLen = s.length;
    const nextS = s.replace(RegExp('^' + regText), '');
    const nextSLen = nextS.length;
    const match = s.slice(0, sLen - nextSLen);
    return [{type: 'TokenText', text: match}, nextS];
  }

  return undefined;
}

export const eatTokens = (s: string): [Token[], string] => {
  const resultTokens: Token[] = [];
  let resultS = s;
  let token: Token;
  while (true) {
    const oneMore = eatToken(resultS);
    if (oneMore === undefined) {
      return [resultTokens, resultS];
    }
    if (resultS.length === oneMore[1].length) {
      return [resultTokens, resultS];
    }
    [token, resultS] = oneMore;
    resultTokens.push(token);
  }
}