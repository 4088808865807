import * as React from 'react';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { Parsed } from 'cls/Markup';

export const parsedToElement = (parsed: Parsed, num: number): JSX.Element => {
  switch (parsed.type) {
    case 'ParsedB': return <b key={num}>
      {parsed.text.map((x, xi) => parsedToElement(x, xi))}
    </b>;
    case 'ParsedI': return <i key={num}>
      {parsed.text.map((x, xi) => parsedToElement(x, xi))}
    </i>;
    case 'ParsedBr': return <br key={num} />;
    case 'ParsedIcon': return <i key={num} className={`${parsed.text} small`} />;
    case 'ParsedButton': {
      return <Button key={num} size="sm" className="pt-1 pb-1" variant={parsed.variant}>
        {parsed.text.map((x, xi) => parsedToElement(x, xi))}
      </Button>;
    }
    case 'ParsedNums': {
      return <div key={num} className="mb-2">
        {parsed.text.map((xs, xi) =>
          <div key={xi} className="d-flex flex-row" style={{lineHeight: "2rem"}}>
            <div className="ml-1 pt-1 pb-1">
              { parsed.orders[xi].split('').map((y, yi) => y === ' ' ?
                <span key={yi} style={{}}>&nbsp;&nbsp;</span> :
                (y === "*" ? "•" : y)
              )}
            </div>
            <div className="pt-1 pb-1">
              {xs.map((x, xi) => parsedToElement(x, xi))}
            </div>
          </div>
        )}
      </div>;
    }
    case 'ParsedRawText': return <span key={num}>{parsed.text}</span>;
    case 'ParsedUrl': {
      const sameSite = parsed.link.includes(window.location.host) || parsed.link.startsWith("/");
      if (sameSite) {
        return (
          <LinkContainer to={parsed.link} key={num}>
            <a href={parsed.link} key={num} target={sameSite ? '_self' : "_blank"} rel={sameSite ? undefined : "noopener noreferrer"}>
              {parsed.text}
            </a>
          </LinkContainer>
        );
      }
      return (
        <a href={parsed.link} key={num} target={sameSite ? '_self' : "_blank"} rel={sameSite ? undefined : "noopener noreferrer"}>
          {parsed.text} <i className="czi-sign-in" />
        </a>
      );
    }
    default: return <span key={num}>???</span>;
  }
}
