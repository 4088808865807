import * as React from 'react';
import { Accordion, Button, Card, Tab, Tabs } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  Content,
  ContentDropdown, ContentDropdownLinks,
  ContentH1,
  ContentH2,
  ContentH3,
  ContentH4,
  ContentH5,
  ContentH6,
  ContentLinks,
  ContentNote,
  ContentTabs,
  ContentText
} from 'cls/Content';
import { parse } from 'cls/Markup';
import { parsedToElement } from 'cls/MarkupToHtml';

const A = (props: {url: string, text: string, hideIcon?: boolean}) => {
  const sameSite = props.url.includes(window.location.host) || props.url.startsWith("/");
  if (sameSite) {
    return (
      <LinkContainer to={props.url} >
        <a href={props.url} target={sameSite ? '_self' : "_blank"} rel={sameSite ? undefined : "noopener noreferrer"}>
          {props.text}
        </a>
      </LinkContainer>
    );
  }
  return (
    <a href={props.url} target={sameSite ? '_self' : "_blank"} rel={sameSite ? undefined : "noopener noreferrer"}>
      {props.text} {!props.hideIcon && <i className="czi-sign-in" /> }
    </a>
  );
}

const H1 = (props: {content: ContentH1}) => <h1 className="pt-3">{props.content.text}</h1>;
const H2 = (props: {content: ContentH2}) => <h2 className="pt-3">{props.content.text}</h2>;
const H3 = (props: {content: ContentH3}) => <h3 className="pt-2">{props.content.text}</h3>;
const H4 = (props: {content: ContentH4}) => <h4 className="pt-2">{props.content.text}</h4>;
const H5 = (props: {content: ContentH5}) => <h5 className="pt-1">{props.content.text}</h5>;
const H6 = (props: {content: ContentH6}) => <h6 className="pt-1">{props.content.text}</h6>;
const Text = (props: {content: ContentText}) => {
  const parsed = parse(props.content.text);
  return <>{parsed.map((x, xi) => parsedToElement(x, xi))}</>;
};
const Note = (props: {content: ContentNote}) => {
  const parsed = parse(props.content.text);
  return <div className="mt-3 mb-3">
    <b>{process.env.REACT_APP_NOTE}:</b> {parsed.map((x, xi) => parsedToElement(x, xi))}
  </div>;
}
const Links = (props: {content: ContentLinks}) => {
  return (
    <div className="ml-2 links">
      <h6 className="">
        <A text={props.content.content.title.text} url={props.content.content.title.url} hideIcon={true} />
      </h6>
      {props.content.content.links.map((x, xi) => (
        <div key={xi}>
          <LinkContainer to={x.url}>
            <Button className="pl-0 widget-list-link text-left" href={x.url} variant="link">
              <i className="czi-document text-primary mr-3"/>
              {x.text}
            </Button>
          </LinkContainer>
        </div>
      ))}
    </div>
  );
};
const Dropdown = (props: {content: ContentDropdown}) => {
  const [status, setStatus] = React.useState<{[key: string]: boolean}>({});
  const setItemStatus = (item: string) => {
    status[item] = !status[item];
    setStatus({...status});
  }
  const defaultValue = props.content.opts?.open === undefined ? undefined : props.content.opts?.open.toString(10);
  return (
    <>
      { props.content.content.map((x, xi) => (
        <Accordion className="help-accordion" key={xi} defaultActiveKey={defaultValue}>
          <Card key={xi}>
            <Accordion.Toggle as={Card.Header} eventKey={xi.toString(10)} onClick={() => setItemStatus(xi.toString(10))}>
              { x.text }
              <span className={`accordion-indicator ${status[xi.toString(10)] ? 'on' : 'off'}`} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={xi.toString(10)}>
              <Card.Body>
                {x.content.map((x, xi) => contentToElement(x, xi))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

        </Accordion>
      ))}
    </>
  );
};

const TabsElement = (props: {content: ContentTabs}) => {
  const defaultValue = props.content.opts?.open === undefined ? undefined : props.content.opts?.open.toString(10);
  return (
    <>
      <Tabs defaultActiveKey={defaultValue}>
        { props.content.content.map((x, xi) => (
          <Tab key={xi.toString(10)} eventKey={xi.toString(10)} title={x.text}>
            {x.content.map((x, xi) => contentToElement(x, xi))}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

const DropdownLinks = (props: {content: ContentDropdownLinks}) => {
  const [status, setStatus] = React.useState<{[key: string]: boolean}>({});
  const setItemStatus = (item: string) => {
    status[item] = !status[item];
    setStatus({...status});
  }
  return (
    <>
      <Accordion className="links-accordion">
        { props.content.content.map((x, xi) => (
          <div key={xi}>
            <Accordion.Toggle as={Card.Header} eventKey={xi.toString(10)} onClick={() => setItemStatus(xi.toString(10))}>
              { x.text }
              <span className={`accordion-indicator ${status[xi.toString(10)] ? 'on' : 'off'}`} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={xi.toString(10)}>
              <div className="d-flex flex-column collapse-body">
                {x.content.map((x, xi) => (
                  <LinkContainer to={x.url} key={xi}>
                    <a href={x.url}>{x.text}</a>
                  </LinkContainer>
                ))}
              </div>
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    </>
  );
};

export const contentToElement = (content: Content, num: number): JSX.Element => {
  switch (content.type) {
    case 'h1': return <H1 key={num} content={content} />;
    case 'h2': return <H2 key={num} content={content} />;
    case 'h3': return <H3 key={num} content={content} />;
    case 'h4': return <H4 key={num} content={content} />;
    case 'h5': return <H5 key={num} content={content} />;
    case 'h6': return <H6 key={num} content={content} />;
    case 'text': return <Text key={num} content={content} />;
    case 'note': return <Note key={num} content={content} />;
    case 'dropdown': return <Dropdown key={num} content={content} />;
    case 'dropdown-links': return <DropdownLinks key={num} content={content} />;
    case 'links': return <Links key={num} content={content} />;
    case 'tabs': return <TabsElement key={num} content={content} />;
    default: return <>???</>;
  }
}