import * as React from 'react';

export const LoadingBox = (props: {top?: string | number, bottom?: string | number}) => {
  return <section
    className="d-flex align-items-center justify-content-center"
    style={{
      paddingTop: props.top === undefined ? '1rem' : props.top,
      marginBottom: props.bottom === undefined ? 1000 : props.bottom,
    }}
  >
    <div>
      <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true" />
      {process.env.REACT_APP_LOADING}...
    </div>
  </section>;
}
