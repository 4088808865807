import enFlat from 'cartzilla/dist/img/flags/en.png';
// eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
import * as React from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';

import plFlag from 'assets/pl-flag.png';

interface Props {
}
interface State {
}

export default class Footer extends React.Component<Props, State> {

  render() {
    const year = new Date().getFullYear();

    return (
      <footer className="bg-darker pt-3 pt-sm-4 pt-md-5 pb-3 pb-sm-4 pl-4">
        <Container>
          <Row>
            <Col xs={12} md={4} className="d-flex flex-row align-content-center justify-content-start">
              <div className="text-nowrap mb-2">
                  <span className="d-inline-block align-middle mt-n1 mr-3 mb-0 text-light h3">
                    {process.env.REACT_APP_NAME}
                  </span>
                <Dropdown className="btn-group disable-autohide">
                  <Dropdown.Toggle variant="outline-light" size="sm" className="px-2">
                    <img className="mr-2" width={20} height={20} src={plFlag} alt={process.env.REACT_APP_PL_LONG}/>
                    {process.env.REACT_APP_PL}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <img className="mr-2" width={20} height={20} src={enFlat} alt={process.env.REACT_APP_EN_LONG}/>
                      {process.env.REACT_APP_EN_LONG}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <img className="mr-2" width={20} height={20} src={plFlag} alt={process.env.REACT_APP_PL_LONG}/>
                      {process.env.REACT_APP_PL_LONG}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col xs={12} md={8} className="d-flex flex-row align-content-center justify-content-start justify-content-md-end">
              <div className="widget widget-links widget-light">
                <ul className="widget-list d-flex flex-wrap justify-content-start">
                  <li className="widget-list-item mr-4">
                    <a className="widget-list-link" href="#">{process.env.REACT_APP_TERMS}</a>
                  </li>
                  <li className="widget-list-item mr-4">
                    <a className="widget-list-link" href="#">{process.env.REACT_APP_PRIVACY_POLICY}</a>
                  </li>
                  <li className="widget-list-item mr-4">
                    <a className="widget-list-link" href="#">{process.env.REACT_APP_HELP}</a>
                  </li>
                  <li className="widget-list-item mr-4">
                    <a className="widget-list-link" href="#">{process.env.REACT_APP_CONTACT}</a>
                  </li>
                  <li className="widget-list-item mr-4">
                    <a className="widget-list-link" href="#">{process.env.REACT_APP_ABOUT_US}</a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="font-size-xs text-light opacity-50">
            © Brokli {year}. {process.env.REACT_APP_ALL_RIGHTS_RESERVED}&nbsp;
          </div>
        </Container>
      </footer>
    );
  }
}
