import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import Api from 'cls/Api';
import { Def } from 'cls/Content';
import { contentToElement } from 'cls/ContentToHtml';
import { StoreState } from 'cls/store';
import { NextStep } from 'content/next-step';
import { LoadingBox } from 'ui/loading-box';
import { Logo } from 'ui/logo';

interface ParamProps {
}
interface ReduxStateProps {
}
interface ReduxDispatchProps {
}
interface Props {
}
interface State {
  def?: Def;
}


class Scene extends React.Component<Props & ParamProps & ReduxStateProps & ReduxDispatchProps, State> {
  constructor(props: Props & ParamProps & ReduxStateProps & ReduxDispatchProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Api.getHelpPage().subscribe((def) => {
      this.setState({def})
    });
  }

  render() {
    const { def } = this.state;
    if (def === undefined) {
      return <LoadingBox top="12rem"/>;
    }

    const content = def.content.map((x, xi) => contentToElement(x, xi));

    return <>
      <Container className="under-nav pt-5 pb-5">

        <div className="d-flex flex-column align-items-center mb-5 pb-5">
          <Logo />
          <h1>{process.env.REACT_APP_HOW_CAN_WE_HELP}</h1>
        </div>
        <Row>
          <Col md={{span: 8, offset: 2}} xs={12}>
            {content}
            <NextStep />
          </Col>
        </Row>
      </Container>
    </>;
  }
}

const mapStateToProps = (): ReduxStateProps => {
  return ({
  });
};

const mapDispatchToProps = () => {
  return {
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const Homepage = () => (
  <ConnectedScene />
);

export default Homepage;
