import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Api from 'cls/Api';
import { Def } from 'cls/Content';
import { contentToElement } from 'cls/ContentToHtml';
import { StoreState } from 'cls/store';
import { NextStep } from 'content/next-step';
import { LoadingBox } from 'ui/loading-box';

interface ParamTypes {
  id?: string;
}
interface ParamProps {
  params: ParamTypes;
}
interface ReduxStateProps {
}
interface ReduxDispatchProps {
}
interface Props {
}
interface State {
  def?: Def;
}


class Scene extends React.Component<Props & ParamProps & ReduxStateProps & ReduxDispatchProps, State> {
  constructor(props: Props & ParamProps & ReduxStateProps & ReduxDispatchProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { params } = this.props;
    if (params.id === undefined) {
      return;
    }
    Api.getHelpPage(params.id).subscribe((def) => {
      this.setState({def})
    });
  }

  componentDidUpdate(prevProps: Props & ParamProps & ReduxStateProps & ReduxDispatchProps) {
    const { params } = this.props;
    if (prevProps.params.id !== params.id) {
      this.setState({def: undefined});
      Api.getHelpPage(params.id).subscribe((def) => {
        this.setState({def});
      });
    }
  }

  render() {
    const { def } = this.state;
    if (def === undefined) {
      return <LoadingBox top="12rem" />;
    }

    const content = def.content.map((x, xi) => contentToElement(x, xi));
    const sidebar = def.sidebar.map((x, xi) => contentToElement(x, xi));

    return <>
      <Container className="under-nav pt-5 pb-5 cms-page">
        <Row>
          <Col md={8}>
            <Card>
              <Card.Body className="p-5">
                {content}
              </Card.Body>
            </Card>
            <NextStep />
          </Col>
          <Col md={4}>
            {sidebar}
          </Col>
        </Row>
      </Container>
    </>;
  }
}

const mapStateToProps = (): ReduxStateProps => {
  return ({
  });
};

const mapDispatchToProps = () => {
  return {
  };
}

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps, mapDispatchToProps,
)(Scene);

const CmsPage = () => (
  <ConnectedScene params={useParams<keyof ParamTypes>()} />
);

export default CmsPage;
