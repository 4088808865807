import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { StoreState } from 'cls/store';
import Urls from 'cls/Urls';
import CmsPage from 'content/cms';
import Homepage from 'content/homepage';
import Footer from 'page/footer';
import Header from 'page/header';

import 'page/main/style.css';

interface Props {}
interface ReduxStateProps {}
interface ReduxDispatchProps {}
interface State {}

class Scene extends React.Component<Props & ReduxDispatchProps & ReduxStateProps, State> {
  render() {
    return (
      <>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path={Urls.HELP_PAGE} element={<CmsPage />} />
        </Routes>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (): ReduxStateProps => {
  return ({});
};

const ConnectedScene = connect<ReduxStateProps, ReduxDispatchProps, Props, StoreState>(
  mapStateToProps,
)(Scene);

const MainScene = () => (
  <ConnectedScene />
);

export default MainScene;
